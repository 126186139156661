

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3

import { denormalisedResponseEntities } from "../../util/data";
import { lastTransition } from "../../util/types";

// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

// ================ Action types ================ //
export const OPEN_LISTING_SUCCESS = 'app/PurchaseImagePage/OPEN_LISTING_SUCCESS';


// ================ Reducer ================ //

const initialState = {
  alltrans: [],

}; 
const PurchageImageListingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;  
  switch (type) {
    case OPEN_LISTING_SUCCESS:
      return {
        alltrans: payload, 
      };
    default:
      return state;
  }
};

export default  PurchageImageListingPageReducer;

// ================ Selectors ================ // 
/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */ 
// ================ Action creators ================ //

export const openListingSuccesst = response => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response,
});


// Throwing error for new (loadData may need that info)
export const getTransactions = queryParams => (dispatch, getState, sdk) => { 
 return sdk.transactions.query(queryParams)
  .then(res => { 
    const response = denormalisedResponseEntities(res)
    return dispatch(openListingSuccesst(response))
  })
  .catch(e => {
    throw e;
  });
};

 
export const loadData = (params, search, config) => {
  const apiQueryParams = { 
    lastTransitions:lastTransition, 
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking', 
    ],
    'fields.transaction': [
      'protectedData',
      'processName',
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems', 
    ],
    'fields.listing': ['title', 'availabilityPlan', 'publicData.listingType'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;
 
  return  getTransactions(apiQueryParams); 
};
